import {useEffect} from 'react';

import {once} from 'lodash';

import {getDomain, useCookies} from '@renofi/utils';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';

export default function useCookieConsent(callback, isLead) {
  const onceCallback = once(callback);
  const {cookie, updateCookie} = useCookies(
    UTM_COOKIE_KEYS['cookieconsent'],
    false,
  );
  useEffect(() => {
    if (cookie || isLead) {
      onceCallback();
    }
  }, [cookie, isLead]);

  const setConsentCookie = () => {
    const domain = getDomain();
    if (domain) {
      updateCookie({value: true, numberOfDays: 365, domain: `.${domain}`});
    }
  };
  return {cookie, setConsentCookie};
}
