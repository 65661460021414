import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import {useScrollToTopBetweenRoutes, useLocalStorage} from '@renofi/utils';
import {Panel} from '@renofi/components';
import {setDataDogUser} from '@renofi/analytics';

import {companyVar, contactVar} from './api/cache';
import {useQuestionnaire} from './api';
import {Wrapper} from './components/components';
import Intro from './components/Intro';
import ContactDetails from './components/ContactDetails';
import CompanyDetails from './components/CompanyDetails';
import Eligibility from './components/Eligibility';
import Experience1 from './components/Experience/Experience-1';
import Experience2 from './components/Experience/Experience-2';
import Experience3 from './components/Experience/Experience-3';
import References from './components/References';
import Sign from './components/Sign';
import Outro from './components/Outro';
import InvalidToken from './components/InvalidToken';
import NotFound from './components/NotFound';
import RejectedDocuments from './components/RejectedDocuments';
import CancelledQuestionnaire from './components/CancelledQuestionnaire';

function App({basePath = '', onFinish, project, questionnaire}) {
  const [isAppReady, setIsAppReady] = useState(false);
  const contact = useReactiveVar(contactVar);
  const company = useReactiveVar(companyVar);
  const [token] = useLocalStorage('renofi:token');
  const questionnaireId = questionnaire?.id;
  useScrollToTopBetweenRoutes();

  const fetchQuestionnaire = useQuestionnaire({project, questionnaire});
  useEffect(() => {
    (async () => {
      await fetchQuestionnaire();
      setIsAppReady(true);
    })();
  }, []);

  useEffect(() => {
    if (token && contact?.email) {
      setDataDogUser({
        id: contact.email,
        ...(company?.businessName ? {name: company.businessName} : {}),
      });
    }
  }, [token, contact?.email]);

  if (!isAppReady) return null;

  return (
    <Wrapper>
      <Panel
        background="white"
        mt={4}
        css={{
          padding: ['0 15px', '0 80px'],
          paddingBottom: [0, 0],
          zIndex: 'auto',
        }}>
        <Switch>
          <Route path={`${basePath}/intro`}>
            <Intro />
          </Route>

          <Route path={`${basePath}/contact-details`}>
            <ContactDetails questionnaireId={questionnaireId} step={1} />
          </Route>

          <Route path={`${basePath}/company-details`}>
            <CompanyDetails questionnaireId={questionnaireId} step={2} />
          </Route>

          <Route path={`${basePath}/eligibility`}>
            <Eligibility questionnaireId={questionnaireId} step={3} />
          </Route>

          <Route path={`${basePath}/experience-1`}>
            <Experience1 questionnaireId={questionnaireId} step={4} />
          </Route>

          <Route path={`${basePath}/experience-2`}>
            <Experience2 questionnaireId={questionnaireId} step={4} />
          </Route>

          <Route path={`${basePath}/experience-3`}>
            <Experience3 questionnaireId={questionnaireId} step={4} />
          </Route>

          <Route path={`${basePath}/references`}>
            <References questionnaireId={questionnaireId} step={5} />
          </Route>

          <Route path={`${basePath}/sign`}>
            <Sign onFinish={onFinish} questionnaireId={questionnaireId} />
          </Route>

          <Route path={`${basePath}/final`}>
            <Outro />
          </Route>

          <Route path={`${basePath}/rejected-documents`}>
            <RejectedDocuments questionnaireId={questionnaireId} />
          </Route>

          <Route path={`${basePath}/confirm-references-details`}>
            <References isConfirmDetails questionnaireId={questionnaireId} />
          </Route>

          <Route path={`${basePath}/invalid-token`}>
            <InvalidToken />
          </Route>

          <Route path={`${basePath}/not-found`}>
            <NotFound />
          </Route>

          <Route path={`${basePath}/cancelled`}>
            <CancelledQuestionnaire />
          </Route>

          <Redirect to={`${basePath}/intro`} />
        </Switch>
      </Panel>
    </Wrapper>
  );
}

App.propTypes = {
  basePath: PropTypes.string,
  onFinish: PropTypes.func,
  questionnaire: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default App;
