import * as Sentry from '@sentry/react';
import {datadogRum} from '@datadog/browser-rum';

import {
  constants,
  formatAMPM,
  formatDayOfWeek,
  formatMonth,
  isSelfReportedB2b2cLead,
  logger,
  notifyToChilipiperSlack,
} from '@renofi/utils';

import {sendEvent} from './analytics';

const ENV = process.env.REACT_APP_ENV;
const isProduction = ENV === 'production';
const isStaging = ENV === 'staging';
let chiliPiperReportingInited = false;

async function submit({
  lead,
  events,
  domElement = '#custom-chilipiper-wrapper',
  callDetails,
  router,
  onSuccess = () => ({}),
  onClose = () => ({}),
  analyticsPrefix,
}) {
  await checkLoaded();

  const {
    firstName,
    lastName,
    email,
    phone,
    stars,
    company,
    nmls,
    state,
    designProjectDetails,
    Renofi_Design_Lead_Status__c,
    utmSource,
    utmMedium,
    utmContent,
    utmTerm,
  } = lead;
  const {open, success} = events;
  const {callSource, callType} = callDetails;

  initChiliPiperReporting(lead, analyticsPrefix, router);
  sendEvent(open);

  const domain = isProduction ? 'renofi' : 'renofi-com';
  const newRouter = isProduction ? router : 'test-router';
  logger.debug(`CP [${ENV}]: given ${router}; sending to ${newRouter}`);

  const SUBMIT_MAX_RETRIES = 3;

  function submitChiliPiper(submitRetryCount, submitMaxRetries) {
    ChiliPiper.submit(domain, newRouter, {
      lead: {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        Rating: stars,
        CallSource: callSource,
        CallType: callType,
        PropertyState: state,
        renofi_design_project_details__c: designProjectDetails,
        renofi_design_status__c: Renofi_Design_Lead_Status__c,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_content: utmContent,
        utm_term: utmTerm,
        ...(company ? {Company: company} : {}),
        ...(nmls ? {NmlsID: nmls} : {}),
      },
      debug: process.env.REACT_APP_ENV !== 'production',
      webHook: 'https://webhooks.renofi.com/api/v1/chili',
      injectRootCss: true,
      map: true,
      domElement,
      onSuccess: (e) => {
        sendEvent(success, e);
        onSuccess(e);
      },
      onError: (err = {}) => {
        const errData = {...err, router: newRouter};
        reportError(analyticsPrefix, errData, lead);

        if (
          errData?.message !== 'Network request failed' &&
          errData?.message !== 'API request timed out'
        ) {
          return;
        }

        if (submitRetryCount < submitMaxRetries) {
          const delay = 1000 * Math.pow(2, submitRetryCount);
          submitRetryCount++;
          logger.debug(
            `Retrying ChiliPiper.submit()... Attempt ${submitRetryCount} after ${delay} ms`,
          );
          sendEvent(`${analyticsPrefix}/Chilipiper-Retry`, {
            retryAttempt: submitRetryCount,
          });
          setTimeout(() => {
            submitChiliPiper(submitRetryCount, submitMaxRetries);
          }, delay);
        } else {
          reportError(
            analyticsPrefix,
            'ChiliPiper-Submit-Retry-Count-Reached',
            lead,
          );
          logger.debug(`Max ChiliPiper.submit() retry count reached`);
        }
      },
      onClose,
    });
  }
  submitChiliPiper(0, SUBMIT_MAX_RETRIES);
}

export function initSchedule({
  lead,
  analyticsPrefix = '',
  domElement,
  router = 'prequal-web-form',
  onSuccess,
  onClose,
}) {
  submit({
    lead,
    domElement,
    router,
    events: {
      open: `${analyticsPrefix}/Schedule-Calendar-Open`,
      success: `${analyticsPrefix}/Schedule-Calendar-Success`,
    },
    callDetails: {
      callSource: analyticsPrefix.toLowerCase(),
      callType: 'scheduled-call',
    },
    onSuccess,
    onClose,
    analyticsPrefix,
  });
}

export function initHotCall({
  lead,
  analyticsPrefix = '',
  domElement,
  router = 'hot-calls',
  onSuccess,
  onClose,
}) {
  submit({
    lead,
    domElement,
    router,
    events: {
      open: `${analyticsPrefix}/HotCall-Calendar-Open`,
      success: `${analyticsPrefix}/HotCall-Calendar-Success`,
    },
    callDetails: {
      callSource: analyticsPrefix.toLowerCase(),
      callType: 'hot-call',
    },
    onSuccess,
    onClose,
    analyticsPrefix,
  });
}

// Check if CP script is loaded and if isn't load again and
// resolve when successfully loaded
function checkLoaded() {
  let resolve;
  if (typeof ChiliPiper === 'undefined') {
    const promise = new Promise((res) => {
      resolve = res;
    });
    const element = document.createElement('script');
    element.src = 'https://js.chilipiper.com/marketing.js';
    element.addEventListener('load', resolve);
    document.body.appendChild(element);
    return promise;
  } else {
    return Promise.resolve();
  }
}

export function initChiliPiperReporting(lead = {}, analyticsPrefix, router) {
  const originalSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function () {
    this.addEventListener('load', async function () {
      if (
        this.responseURL?.includes('https://api.chilipiper.com') &&
        this.status >= 300
      ) {
        const message = await this.response.text();
        const errData = {message, error: message, router};
        reportError(analyticsPrefix, errData, lead);
      }
    });
    originalSend.apply(this, arguments);
  };

  const onMessage = (event) => {
    const {data} = event;
    const {action, args = {}} = data || {};
    if (action === 'no-free-slots') {
      const errData = {message: action, error: action, router, args};
      reportError(analyticsPrefix, errData, lead);
    }
  };

  if (chiliPiperReportingInited) return;

  window.addEventListener('message', onMessage, false);
  chiliPiperReportingInited = true;
}

function reportError(analyticsPrefix, errData, lead) {
  if (isStaging) return;

  const data = {
    chilipiper: errData,
    url: window.location.href,
    lead,
  };
  sendEvent(`${analyticsPrefix}/Chilipiper-Error`, data);

  datadogRum.addError('Chilipiper-Error', data);
  const sentryId = Sentry.captureException(new Error('Chilipiper-Error'), {
    extra: data,
  });

  notifyToChilipiperSlack({
    message: errData?.message,
    router: errData?.router,
    name: `${lead?.firstName} ${lead?.lastName}`,
    email: `${lead?.email}`,
    id: `${lead?.id}`,
    sentryUrl: `https://sentry.io/organizations/renofi/issues/?query=${sentryId}`,
    sessionReplayUrl: datadogRum.getSessionReplayLink(),
    url: window.location.href,
  });
}

export function getRouterByLead(lead) {
  const {contractor, partnerSite} = constants.LEAD_ORIGIN_TYPE;
  if (
    [contractor, partnerSite].includes(lead.leadExternalOriginType) ||
    isSelfReportedB2b2cLead(lead)
  ) {
    return 'b2b2c-pos';
  }
  if (lead.utmCampaign === 'sr-lo' || lead.utmCampaign === 'lo-b2b2c') {
    return 'sr-loan-officers';
  }
  return 'prequal-web-form';
}

export const parseCallDetails = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  return {
    day: formatDayOfWeek(startDate),
    month: formatMonth(startDate, true),
    date: startDate.getDate(),
    startTime: formatAMPM(startDate, {upper: true}),
    endTime: formatAMPM(endDate, {upper: true}),
  };
};

export const parseCallDetailsQueryParams = () => {
  const search = window.location.search;
  const queryParams = new URLSearchParams(search);

  const callStart = queryParams.get('callStart');
  const callEnd = queryParams.get('callEnd');

  if (callStart && callEnd) {
    return parseCallDetails(Number(callStart), Number(callEnd));
  }

  return null;
};
